<template>
  <div>
    <NavigationBar
      titel="Wissensdatenbank"
      isPreview
      :actions="actions"
    ></NavigationBar>

    <!-- <TippCard
      hintID="Meine_Organisation_News_1_V1"
      text="Veröffentlichte Neuigkeiten werden hier angezeigt. Wichtige Informationen können so an einem Ort abgerufen werden."
    >
    </TippCard> -->
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <!-- seach banner  -->
              <v-card
                class="faq-bg d-flex align-center justify-center text-center mb-9"
              >
                <v-card-text>
                  <v-form class="faq-search-input mx-auto">
                    <v-text-field
                      v-model="knowledgeBaseSearchQuery"
                      outlined
                      placeholder="Artikel durchsuchen"
                      hide-details="auto"
                      class="faq-search-input"
                    >
                      <template #prepend-inner>
                        <v-icon size="23" class="mx-1"> mdi-magnify </v-icon>
                      </template>
                    </v-text-field>
                  </v-form>

                  <p class="mt-4 mb-0">
                    oder wähle eine Kategorie, um zugeordnete Artikel
                    anzuschauen.
                  </p>
                </v-card-text>
              </v-card>

              <v-row class="match-height">
                <v-col
                  v-for="article in allArticles"
                  :key="article.title"
                  cols="12"
                  sm="6"
                  lg="4"
                >
                  <v-card>
                    <v-card-title
                      ><div class="d-flex align-center">
                        <v-avatar
                          rounded
                          color="primary"
                          size="38"
                          class="mr-3"
                        >
                          <v-icon size="24" dark>{{ article.icon }}</v-icon>
                        </v-avatar>
                        {{ article.title }}
                      </div></v-card-title
                    >

                    <v-card-text>
                      <v-list class="card-list px-0" nav>
                        <v-list-item
                          v-for="(item, index) in article.articles"
                          :key="index"
                          link
                          class="mb-0"
                        >
                          <!-- :to="{
                            name: 'front-pages-help-center-article-title',
                            params: {
                              title: 'how-to-add-product-in-cart',
                            },
                          }" -->
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action
                            ><v-icon small
                              >mdi-chevron-right</v-icon
                            ></v-list-item-action
                          >
                        </v-list-item>
                      </v-list>

                      <div
                        class="text-base font-weight-medium mt-4 d-flex align-center gap-x-3"
                      >
                        <div class="d-flex align-center gap-x-3">
                          <router-link
                            :to="{
                              name: 'front-pages-help-center-article-title',
                              params: {
                                title: 'how-to-add-product-in-cart',
                              },
                            }"
                          >
                            <div class="mr-1">
                              Alle {{ article.articles.length }} Artikel
                              anzeigen
                            </div>
                          </router-link>
                          <v-icon size="18" color="primary"
                            >mdi-arrow-right</v-icon
                          >
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row
                ><v-col>
                  <v-card>
                    <v-card-text class="text-center">
                      <p class="text-xl font-weight-medium text--primary mb-1">
                        Offene Fragen?
                      </p>
                      <p class="mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col></v-row
              >
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
// import Subheader from "@/components/_systemwide/Subheader.vue";
// import TippCard from "@/components/_systemwide/Tipp_Card.vue";
// import NewsListItems from "@/components/my-organization/news/NewsListItems.vue";
// import { MY_ORGANIZATION_NEWS_create } from "@/data/permission-types.js";
// import { MY_ORGANIZATION } from "@/store/modules.js";
// import { GET_NEWS } from "@/store/action-types.js";

export default {
  name: "my-organization-knowledge-base",
  components: {
    NavigationBar,
    // Subheader,
    // TippCard,
    // NewsListItems,
  },
  data() {
    return {
      footerProps: {
        "items-per-page-options": [10, 25, 50, -1],
      },

      allArticles: [
        {
          title: "Category title",
          icon: "mdi-cart",
          articles: [
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
          ],
        },
        {
          title: "Category title",
          icon: "mdi-forum",
          articles: [
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
          ],
        },
        {
          title: "Category title",
          icon: "mdi-license",
          articles: [
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
          ],
        },
        {
          title: "Category title",
          icon: "mdi-information-variant",
          articles: [
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
          ],
        },
        {
          title: "Category title",
          icon: "mdi-car",
          articles: [
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
          ],
        },
        {
          title: "Category title",
          icon: "mdi-toy-brick",
          articles: [
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
            { title: "Lorem ipsum dolor sit amet..." },
          ],
        },
      ],

      actions: [],
    };
  },
  computed: {
    news() {
      return this.$store.state.myOrganization.news;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      // this.$store.dispatch(`${MY_ORGANIZATION}${GET_NEWS}`, {
      //   organizationId: this.$route.params.organizationId,
      // });
    },
    createItem() {
      this.$router.push({
        name: "my-organization-knowledge-base-article-new",
        params: {
          organizationId: this.$route.params.organizationId,
        },
      });
    },
  },
};
</script>
